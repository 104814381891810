require('jquery')
require('corporation/js/template/core/popper.min')
require('corporation/js/template/core/bootstrap.min')
require('corporation/js/template/demo')
require('corporation/js/before_login.js')

$(document).ready(function() {
  demo.checkFullPageBackgroundImage();
});

